<template>
<div class="content">
  <h1 class="title">
    {{ $route.meta.heading }}
  </h1>
  <o-dropdown
    aria-role="list"
    position="bottom-left"
    class="menu-dropdown"
  >
    <template #trigger="{  }">
      <o-button variant="primary">
        <span>Menu</span>
      </o-button>
    </template>
    <o-dropdown-item aria-role="listitem" @click="gotoHome">
      Home
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoItems">
      Items
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoBasket">
      Basket
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoEvTraining">
      EV Training
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoMeals">
      Meals
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoRaids">
      Herba Mystica Raids
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoSandwiches">
      Sandwiches
    </o-dropdown-item>
    <o-dropdown-item aria-role="listitem" @click="gotoAbout">
      About
    </o-dropdown-item>
  </o-dropdown>
</div>
</template>

<script>
import '@oruga-ui/oruga-next/dist/oruga.css'
export default {
  methods: {
    gotoHome: function () {
      this.$router.push('/')
    },
    gotoItems: function () {
      this.$router.push('/items')
    },
    gotoBasket: function () {
      this.$router.push('/basket')
    },
    gotoEvTraining: function () {
      this.$router.push('/evtraining')
    },
    gotoMeals: function () {
      this.$router.push('/meals')
    },
    gotoRaids: function () {
      this.$router.push('/raids')
    },
    gotoSandwiches: function () {
      this.$router.push('/sandwiches')
    },
    gotoAbout: function () {
      this.$router.push('/about')
    }
  }
}
</script>

<style scoped>
.title {
  padding-top: 10px;
  padding-left: 10px;
}

.menu-dropdown {
  top: 10px;
  right: 10px;
  position: absolute;
}
</style>
