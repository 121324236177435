<template>
  <div class="dez-section">
    <div class="box">
      <h2 class="title">
        Updates
      </h2>
      <div>
        <div class="updates content"
          v-for="(update, index) in updates"
          :key="index"
        >
          <h3 class="subtitle">
            {{ update.version }} - {{ update.description }}
          </h3>
          <ul>
            <li v-for="(note, idy) in update.notes" :key="`note-${idy}`">{{ note }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data: function () {
    return {
      updates: [{
        version: '1.0.0',
        description: 'Shopping 101',
        notes: [
          'Created all of the data',
          'The lookups for different types of items'
        ]
      }, {
        version: '1.0.1',
        description: 'Shopping Baskets',
        notes: [
          'Fixed the fact that Ham had the wrong text',
          'Added the Basket functionality to allow you to search for stores to buy stuff'
        ]
      }, {
        version: '1.0.2',
        description: 'Sword/Shield throwback',
        notes: [
          'Introduce dark mode... And for the moment I\'m making everyone use it',
          'Added an about page...'
        ]
      }, {
        version: '1.0.3',
        description: 'Training 101',
        notes: [
          'Added a more global heading setting, yay for learning new tricks',
          'Started building out an EV training guide, more to coming in the next few days',
          'Moved the menu button, not sure how I feel about it. Let me know your thoughts on Twitter @Dezzles?'
        ]
      }, {
        version: '1.1.0',
        description: 'Going out for a meal?',
        notes: [
          'Add the ability to search in game meals for various powers'
        ]
      }, {
        version: '1.2.0',
        description: 'Of course there are...',
        notes: [
          'Added the 151 sandwich recipes... Because of course there are 151 sandwiches...',
          'Added some info about best 6 star raids for herbs'
        ]
      }, {
        version: '1.2.1',
        description: 'Herba Mystica Raids',
        notes: [
          'Updates to include the best Pokemon to battle in Kitakami and Blueberry Academy 6 Star raids for Herba Mystica'
        ]
      }].reverse()
    }
  }
}
</script>
