<template>
  <div class="common-layout">
    <main-header />
    <router-view/>
  </div>
</template>

<style>

.flex-grow {
  flex-grow: 1;
}

</style>

<script>
import MainHeader from './components/MainHeader.vue'
export default {
  components: {
    MainHeader
  }

}
</script>
