import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import '@oruga-ui/oruga-next/dist/oruga.css'
import '@oruga-ui/theme-bulma/dist/bulma.css'
import '@mdi/font/css/materialdesignicons.min.css'
import './scss/variables.scss'
import './scss/global.scss'
import './scss/table.scss'
createApp(App)
  .use(store)
  .use(Oruga, bulmaConfig)
  .use(router)
  .mount('#app')
