import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      heading: 'Home'
    },
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      heading: 'Search'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  {
    path: '/items',
    name: 'Items',
    meta: {
      heading: 'Items'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Items.vue')
  },
  {
    path: '/item/:itemId',
    name: 'Item',
    meta: {
      heading: 'Item'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Item.vue')
  },
  {
    path: '/basket',
    name: 'Basket',
    meta: {
      heading: 'Shopping Basket'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Basket.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      heading: 'About'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/evtraining',
    name: 'EVTraining',
    meta: {
      heading: 'EV Training'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/EvTraining.vue')
  },
  {
    path: '/meals',
    name: 'Meals',
    meta: {
      heading: 'Meals'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Meals.vue')
  },
  {
    path: '/sandwiches',
    name: 'Sandwiches',
    meta: {
      heading: 'Sandwiches'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Sandwiches.vue')
  },
  {
    path: '/raids',
    name: 'Raids',
    meta: {
      heading: 'Herba Mystica Raids'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Raids.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
